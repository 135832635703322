<template>
  <div class="infoSing">
    <div class="title">
      {{ dataList.contractName }}
    </div>
    <div class="img-list" ref="scroll" v-loading="finish">
      <van-image v-for="(item,index) in fileList"  :key="index" :src="item.url" alt="" class="imgs"  @click="open(index)" fit="scale-down"/>

      <!--   <img v-if="resultImg" src="" class="imgs" alt="" id="myImg">-->
    </div>
    <van-popup
        v-model="signatureShow"
        class="signature">
      <vue-esign
          ref="esign"
          :height="300"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
          :isClearBgColor="false">
      </vue-esign>
      <div class="sign-bottom">
        <van-button class="sign-btn" @click="handleReset">重绘</van-button>
        <van-button class="sign-btn confirm" @click="handleGenerate">保存</van-button>
      </div>
    </van-popup>
    <van-button type="primary" block color="#4581F8" @click="signatureShow=true" v-if="state&&dataList.isSign==1&&fileList.length>0"> 去签字</van-button>
  </div>
</template>

<script>
import vueEsign from 'vue-esign'
import {dataURLtoFile} from '@/utils/utils'
import {getImageStream} from "@/utils";
import { ImagePreview } from "vant";
export default {
  components: {
    vueEsign
  },
  data() {
    return {
      id:'',
      signList:[],
      finish:true,
      state:true,
      userId:this.$globalData.userInfo.userId,
      urlList:[],
      fileList: [], //渲染的文件
      virtualFile:[],//临时存储文件
      dataList: [],
      dataForm: {
        checkpatrolPeople: [],
        signFilesEntity: [], // 签名文件
        reviewPeople: '', // 复查人员
      },
      resultImg: '',
      signType: '',
      signatureShow: false,
      signUploadPath: '',
      patrolUserId: '',
      isCrop: true, //是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      lineWidth: 4, //签名画笔粗细
      lineColor: '#000000', // 签名画笔颜色
      bgColor: '#FFFFFF', //画布背景色，为空时画布背景透明，支持多种格式 '#ccc'，'#E5A1A1'，'rgb(229, 161, 161)'，'rgba(0,0,0,.6)'，'red'
      title: ''
    }
  },
  mounted() {
    let imgSAll=document.querySelectorAll('.van-image__img')
    console.log(imgSAll)
    // imgSAll.forEach((item,index)=> {
    //   if (item.complete) {
    //     // 图片已经加载完成
    //     console.log(item.naturalWidth, item.naturalHeight);
    //     console.log(item.width, item.height);
    //   } else {
    //     // 图片未加载完成，绑定 onload 事件
    //     item.onload = function () {
    //       console.log(item.naturalWidth, item.naturalHeight);
    //       console.log(item.width, item.height);
    //     };
    //   }
    // })
  },
  created() {
    this.signList=[]
    this.dataList = []
    if (this.$route.query) {
      this.dataList = this.$route.query.data
    }
    this.init(this.dataList)
    this.finish=false
  },
  watch: {

  },
  methods: {
    open(index){
      ImagePreview({
        images:this.urlList, //需要预览的图片 URL 数组
        showIndex:true, //是否显示页码
        loop:false, //是否开启循环播放
        startPosition:index //图片预览起始位置索引
      })
    },
    init(data) {
       this.id=data.contractId
      console.log(data)
      let timestamp = Date.parse(new Date(data.endTime).toString());
      var timeNow = Date.parse(new Date());
     if(timeNow>timestamp){
    this.$toast.fail('签约已截止')
    this.state=false
  }

      if (data.filesUrl) {
        this.fileList = []
        this.urlList=[]
        this.virtualFile=[]
        data.filesUrl.split(',').forEach((item, index) => {
          let u;
          u = getImageStream(item)
          let url = {
            // fileSize:'',
            // newFileName:'',
            // objectUrl:'',
            // oldFileName:'',
            // parameter:'',
            // parameters:'',
            // suffix:'png',
            uid: this.fileList.length,
            id: index,//多图需要记录一个uid，随图片数量增加而增加
            status: 'done',//uploading上传中，done上传完成，error上传失败
            fileType: 'image',
            relativePath: item,
            url: u,
            realUrl: item.replace(/\\/g, "/")
          }
          this.fileList.push(url);
          this.urlList.push(u)
          this.virtualFile.push(url)
        })

      }
     this.signUploadPath= `files/contract/${this.dataList.community}/${this.dataList.contractId}`
    },
    handleReset() {
      this.resultImg = ''
      this.$refs.esign.reset()
    },
     handleGenerate() {
      this.signList=[]
      // this.src='/static/pdf/web/viewer.html?file=' + encodeURIComponent(item.url)
      this.$refs.esign.generate().then(res => {
        let resultImg = ''
        if (this.patrolUserId) {
          this.dataForm.checkpatrolPeople.forEach(item => {
            if (item.value == this.patrolUserId) {
              item.signImg = res
              resultImg = res
            }
          })
        } else {
          this.resultImg = res
          resultImg = res
        }
        this.signatureShow = false
        let file = dataURLtoFile(resultImg, '签名.jpg')

        let formdata = new FormData();
        formdata.append("file", file)
        formdata.append("path", this.signUploadPath)
       this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then( async  ({data}) => {
          if (data && data.code === 0) {
            let repairFilesEntity = {
              relativePath: data.fileMessage.relativePath,
            }
            this.signList.push(repairFilesEntity)
            await this.SendSign()
            // this.drawAndShareImage(this.virtualFile[this.virtualFile.length - 1].url, data.fileMessage.objectUrl)
            this.$refs.esign.reset()
          }
        })
      }).catch(err => {
        if (err) {
          console.log('err', err);
          alert('请签名后保存') // 画布没有签字时会执行这里 'Not Signned'
        }
      })
    },

    //上传签名
     SendSign(){
       this.$http({
         url: this.$http.adornUrl('/wxapp/contract/user/getImage'),
         method: 'post',
         data: this.$http.adornData({
           contractId:this.id,
           fileMessages:  this.signList,
         })
       }).then(async({data}) => {
         if (data && data.code === 0) {
           this.state=false
            this.fileList=[]
           this.urlList=[]
       let i=0;
           for( i=0;i<data.images.length;i++){
             let u;
             u = getImageStream(data.images[i])
             let url = {
               uid: this.fileList.length,
               id: i,//多图需要记录一个uid，随图片数量增加而增加
               status: 'done',//uploading上传中，done上传完成，error上传失败
               fileType: 'jpg',
               relativePath: data.images[i],
               url: u,
               realUrl: data.images[i].replace(/\\/g, "/")
             }
             this.fileList.push(url);
             this.urlList.push(u)

           }
           console.log(this.fileList)
           await this.updateFile()
           this.$forceUpdate()
         }
         else{
           this.$toast.fail(data.msg)
         }
       })
     },
    // drawAndShareImage(imgSrc1, imgSrc2) {
    //   let height = document.getElementsByTagName('img')[0].height
    //   //imgSrc1 背景图片
    //   //imgSrc2 小图片
    //   var canvas = document.createElement("canvas");
    //   canvas.width = 690;
    //   canvas.height = height * 2;
    //   var context = canvas.getContext("2d");
    //
    //   context.rect(0, 0, canvas.width, canvas.height);
    //   // context.fillStyle = "#fff";
    //   context.fill();
    //
    //   var myImage = new Image();
    //   myImage.src = imgSrc1; //背景图片  你自己本地的图片或者在线图片
    //   myImage.crossOrigin = 'Anonymous';
    //
    //   myImage.onload = () => {
    //     context.drawImage(myImage, 0, 0, 690, canvas.height);
    //     var myImage2 = new Image();
    //     myImage2.src = imgSrc2; //你自己本地的图片或者在线图片
    //     myImage2.crossOrigin = 'Anonymous';
    //
    //     myImage2.onload = () => {
    //       // 设置填充的颜色
    //       context.fillStyle = "rgba(225,225,225,0)";
    //       // 绘制填充的矩形
    //       // context.fillRect(450, 200, 100, 100);
    //       context.drawImage(myImage2, 174, 260, 40, 30);
    //       var base64 = canvas.toDataURL("image/png"); //"image/png"
    //       this.mergePicture(base64)
    //       // 获取图片容器
    //       // var myImg = document.getElementById('myImg');
    //       // myImg.src = base64;
    //     }
    //   }
    // },
    // //合并图片上传
    // mergePicture(files) {
    //   this.$toast.loading({ duration: 0, message: '正在上传...', forbidClick: true, });
    //   let file = dataURLtoFile(files, '文件签字.png')
    //   let formdata = new FormData();
    //   formdata.append("file", file)
    //   formdata.append("path", this.signUploadPath)
    //   this.$http({
    //     url: this.$http.adornUrl('/wxapp/file/upload'),
    //     method: 'post',
    //     data: formdata
    //   }).then(({data}) => {
    //     if (data && data.code === 0) {
    //       let path = data.fileMessage.relativePath
    //       let u;
    //       u = getImageStream(path)
    //       let url = {
    //         aaa:'ly',
    //         uid: this.virtualFile.length-1,
    //         id: this.virtualFile.length-1,//多图需要记录一个uid，随图片数量增加而增加
    //         status: 'done',//uploading上传中，done上传完成，error上传失败
    //         fileType: 'image',
    //         relativePath: path,
    //         url: u,
    //         realUrl: path.replace(/\\/g, "/")
    //       }
    //       this.virtualFile[this.virtualFile.length-1]=url; //存放临时文件
    //       this.$refs.esign.reset()
    //       this.updateFile()
    //     }
    //     else{
    //       this.$toast.clear()
    //       this.$toast.fail('上传失败,请联系管理员')
    //     }
    //   })
    // },
    //
    // //更新用户文件
    updateFile(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/contract/user/sign'),
        method: 'post',
        data:this.$http.adornData({
          userId:this.userId,
          contractId:this.dataList.contractId.toString(),
          houseId:this.dataList.houseId.toString(),
          fileMessages: this.fileList,
        })
      }).then(({data}) =>{
          if(data.code==0&&data) {
            if (data.isSuccess == true) {
            // 更新成功  替换渲染文件
              this.$toast.success('签约成功')
               this.state=false
             setTimeout(() => {
              var div = this.$refs.scroll;
              console.log(div.scrollHeight)
              div.scrollTo({top: div.scrollHeight, behavior: 'smooth'});
            }, 100)
          }
          }
          else{
            this.$toast.clear()
            this.$toast.fail('签约失败,请联系管理员')
          }
        })


    },
  },

}
</script>
<style scoped lang="scss">

.infoSing {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    padding: 26px 38px;
    width: 750px;
    background-color: white;
    min-height: 88px;
    font-family: 'PingFangSC-Regular', serif;
    font-weight: 600;
    font-size: 32px;
    color: #333333;
    line-height: 44px;
    text-align: left;
    font-style: normal;
  }

  .img-list {
    margin-top: 16px;
    background: #FFFFFF;
    width: 690px;
    height: 1124px;
    overflow-y: scroll;
    position: relative;

    .imgs {
      //width: 690px;
      margin-bottom: 10px;
    }

    .sign-img {
      width: 690px;
      //height: 270px;
      //background: #F5F5F5;
      //border-radius: 8px;
    }
  }

  .van-button {
    width: 690px;
    margin-top: 40px;
  }
}

.signature {
  width: 90%;
  border-radius: 16px;

  .sign-bottom {
    display: flex;
    justify-content: space-between;

    .sign-btn {
      flex: 1;
    }

    .confirm {
      font-weight: bold;
      color: #4581F8;
    }
  }
}
</style>